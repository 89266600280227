import {
  CustomFieldLocations,
  FetchCustomFieldsSortTypes
} from './customFieldsTypes';

import { customFieldsKeys } from '../../locales/keys';

export const INITIAL_CUSTOM_FIELD_FILTERS = {};
export const INITIAL_CUSTOM_FIELD_LIMIT = 1000;
export const INITIAL_CUSTOM_FIELD_PAGE = 1;
export const INITIAL_CUSTOM_FIELD_SORT = [
  FetchCustomFieldsSortTypes.CREATED_AT_ASC
];

export const customFieldLocationsList = [
  CustomFieldLocations.TITLE,
  CustomFieldLocations.HEADER,
  CustomFieldLocations.BILL_FROM,
  CustomFieldLocations.BILL_TO,
  CustomFieldLocations.BEFORE_ITEMS,
  CustomFieldLocations.AMOUNT_DUE,
  CustomFieldLocations.NOTES_AND_TERMS
];

export const customFieldLocationsI18nTexts = {
  [CustomFieldLocations.TITLE]: customFieldsKeys.locations.invoiceTitle,
  [CustomFieldLocations.HEADER]: customFieldsKeys.locations.invoiceHeader,
  [CustomFieldLocations.BILL_FROM]: customFieldsKeys.locations.billFrom,
  [CustomFieldLocations.BILL_TO]: customFieldsKeys.locations.billTo,
  [CustomFieldLocations.BEFORE_ITEMS]: customFieldsKeys.locations.beforeItems,
  [CustomFieldLocations.AMOUNT_DUE]: customFieldsKeys.locations.amountDue,
  [CustomFieldLocations.NOTES_AND_TERMS]:
    customFieldsKeys.locations.notesAndTerms
};
