import {
  CreateCustomFieldValueCacheKeys,
  CreateCustomFieldValueGqlQuery
} from '../../customFieldValuesTypes';

import { useFinCreateQuery } from '../../../common/hooks/base/reactQuery/useFinCreateQuery';

interface CreateCustomFieldValueOptions {
  query: CreateCustomFieldValueGqlQuery;
  cacheKeys?: CreateCustomFieldValueCacheKeys;
}

export interface CreateCustomFieldValueResponse {
  createCustomField: {
    statusCode: number;
    message: string;
    recordId: string;
  };
}

export interface CreateCustomFieldValueInput {
  companyId: string;
  customFieldId: string;
  defaultAt?: string;
  text: string;
}

// TODO:
// Add error type for nest gql mutaion response
export type CreateCustomFieldValueError = null;

const action = 'createCustomFieldValue';

function useCreateCustomFieldValueValue<
  CreateCustomFieldValueRecordType = unknown
>({ query, cacheKeys }: CreateCustomFieldValueOptions) {
  const {
    createQuery,
    createQueryData,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading,
    createQueryReset
  } = useFinCreateQuery<
    CreateCustomFieldValueInput,
    CreateCustomFieldValueResponse,
    CreateCustomFieldValueError,
    CreateCustomFieldValueRecordType
  >({ action, query, cacheKeys });

  return {
    createCustomFieldValue: createQuery,
    createCustomFieldValueData: createQueryData,
    createCustomFieldValueError: createQueryError,
    createCustomFieldValueErrorMessage: createQueryErrorMessage,
    createCustomFieldValueLoading: createQueryLoading,
    createCustomFieldValueReset: createQueryReset
  };
}

export default useCreateCustomFieldValueValue;
