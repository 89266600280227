import { CompanyNanoID } from '../companies/companiesTypes';
import { ProjectNanoID } from '../projects/projectsTypes';
import { InvoiceNanoID } from '../invoices/invoicesTypes';

export class GroupedItemCache {
  static proformaProjectGroupedItemsCacheKey(
    companyNanoId: CompanyNanoID,
    projectNanoId: ProjectNanoID
  ) {
    return `grouped-items-company-${companyNanoId}-project-${projectNanoId}`;
  }

  static proformaCompanyGroupedItemsCacheKey(companyNanoId: CompanyNanoID) {
    return `grouped-items-company-${companyNanoId}`;
  }

  static invoiceGroupedItemsCacheKey(invoiceNanoId: InvoiceNanoID) {
    return `grouped-items-invoice-${invoiceNanoId}`;
  }
}
