import React, { useCallback } from 'react';

import { ClassName, ID } from '../../../../../types';

import { ThreeStatesCheckbox } from '../../../../../helpers/ThreeStatesCheckbox';

interface IndexTableThreeStatesCheckboxProps {
  className?: ClassName;
  checkBoxClassName?: ClassName;
  scope: string;
  indeterminate?: boolean;
  itemId: ID;
  checked: boolean;
  onCheck: (value: ID) => void;
}

function IndexTableThreeStatesCheckbox({
  className,
  checkBoxClassName,
  scope,
  indeterminate = false,
  itemId,
  checked,
  onCheck
}: IndexTableThreeStatesCheckboxProps) {
  const handleCheck = useCallback(() => onCheck(itemId), [itemId, onCheck]);

  return (
    <div
      className={
        className || 'flex items-center justify-center absolute inset-0'
      }
    >
      <ThreeStatesCheckbox
        checkboxClassName={checkBoxClassName}
        checked={checked}
        id={`check_${scope}_${itemId}`}
        indeterminate={indeterminate}
        onChange={handleCheck}
      />
    </div>
  );
}

export default IndexTableThreeStatesCheckbox;
