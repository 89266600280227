import { gql } from 'graphql-request';

export const CREATE_CUSTOM_FIELD_QUERY = gql`
  mutation CreateCustomField(
    $body: String
    $checkedAt: Date
    $companyId: ID!
    $label: String!
    $location: String!
    $options: JSON
    $order: Float
  ) {
    createCustomField(
      input: {
        body: $body
        checkedAt: $checkedAt
        companyId: $companyId
        label: $label
        location: $location
        options: $options
        order: $order
      }
    ) {
      errors {
        fullMessages
      }
      record {
        body
        checkedAt
        companyId
        createdAt
        customFieldValues {
          companyId
          createdAt
          customFieldId
          defaultAt
          id
          text
          updatedAt
          uuid
        }
        id
        label
        location
        options
        updatedAt
        userId
        uuid
      }
      recordId
      recordNanoId
      recordUuid
      status
    }
  }
`;
