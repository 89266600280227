import { gql } from 'graphql-request';

export const CREATE_CUSTOM_FIELD_VALUE_QUERY = gql`
  mutation CreateCustomFieldValue(
    $companyId: ID!
    $customFieldId: ID!
    $defaultAt: Date
    $text: String!
  ) {
    createCustomFieldValue(
      input: {
        companyId: $companyId
        customFieldId: $customFieldId
        defaultAt: $defaultAt
        text: $text
      }
    ) {
      errors {
        fullMessages
      }
      record {
        companyId
        createdAt
        customFieldId
        defaultAt
        id
        text
        updatedAt
        uuid
      }
      recordId
      recordNanoId
      recordUuid
      status
    }
  }
`;
