import {
  CreateCustomFieldCacheKeys,
  CreateCustomFieldGqlQuery
} from '../../customFieldsTypes';

import { useFinCreateQuery } from '../../../common/hooks/base/reactQuery/useFinCreateQuery';

interface CreateCustomFieldOptions {
  query: CreateCustomFieldGqlQuery;
  cacheKeys?: CreateCustomFieldCacheKeys;
}

export interface CreateCustomFieldResponse {
  createCustomField: {
    statusCode: number;
    message: string;
    recordId: string;
  };
}

export interface CreateCustomFieldInput {
  companyId: string;
  checkedAt?: string;
  label?: string;
  body?: string;
  location: string;
  order?: number;
  options?: Record<string, any>;
}

// TODO:
// Add error type for nest gql mutaion response
export type CreateCustomFieldError = null;

const action = 'createCustomField';

function useCreateCustomField<CreateCustomFieldRecordType = unknown>({
  query,
  cacheKeys
}: CreateCustomFieldOptions) {
  const {
    createQuery,
    createQueryData,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading,
    createQueryReset
  } = useFinCreateQuery<
    CreateCustomFieldInput,
    CreateCustomFieldResponse,
    CreateCustomFieldError,
    CreateCustomFieldRecordType
  >({ action, query, cacheKeys });

  return {
    createCustomField: createQuery,
    createCustomFieldData: createQueryData,
    createCustomFieldError: createQueryError,
    createCustomFieldErrorMessage: createQueryErrorMessage,
    createCustomFieldLoading: createQueryLoading,
    createCustomFieldReset: createQueryReset
  };
}

export default useCreateCustomField;
