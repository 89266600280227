import {
  FetchCustomFieldsCacheKey,
  FetchCustomFieldsFilters,
  FetchCustomFieldsLimit,
  FetchCustomFieldsPage,
  FetchCustomFieldsSort,
  FetchCustomFieldsGqlQuery
} from '../../customFieldsTypes';
import { IndexQueryDefaultOptionsOpts } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

import {
  INITIAL_CUSTOM_FIELD_FILTERS,
  INITIAL_CUSTOM_FIELD_LIMIT,
  INITIAL_CUSTOM_FIELD_PAGE,
  INITIAL_CUSTOM_FIELD_SORT
} from '../../customFieldsConstants';

interface PaginatedCustomFieldsOptions<FetchCustomFieldsCustomFieldType> {
  cacheKey: FetchCustomFieldsCacheKey;
  initialFilters?: FetchCustomFieldsFilters;
  initialSort?: FetchCustomFieldsSort;
  initialPage?: FetchCustomFieldsPage;
  initialLimit?: FetchCustomFieldsLimit;
  options?: IndexQueryDefaultOptionsOpts<FetchCustomFieldsCustomFieldType>;
  query: FetchCustomFieldsGqlQuery;
}

const scope = 'customFields';

function usePaginatedCustomFields<FetchCustomFieldsCustomFieldType>({
  cacheKey,
  initialFilters = INITIAL_CUSTOM_FIELD_FILTERS,
  initialSort = INITIAL_CUSTOM_FIELD_SORT,
  initialPage = INITIAL_CUSTOM_FIELD_PAGE,
  initialLimit = INITIAL_CUSTOM_FIELD_LIMIT,
  options = {},
  query
}: PaginatedCustomFieldsOptions<FetchCustomFieldsCustomFieldType>) {
  const {
    data,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    updateItemCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems
  } = useFinIndexQuery<FetchCustomFieldsCustomFieldType>({
    cacheKey,
    scope,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    options,
    query
  });

  return {
    customFieldsData: data,
    customFields: items,
    customFieldsError: itemsError,
    customFieldsErrorMessage: itemsErrorMessage,
    customFieldsTotalCount: itemsTotalCount,
    customFieldsFetched: isFetched,
    customFieldsLoading: isLoading,
    customFieldsIsPlaceholderData: isPlaceholderData,
    customFieldsFilters: currentFilters,
    customFieldsSort: currentSort,
    customFieldsPage: currentPage,
    customFieldsLimit: currentLimit,
    updateCustomFieldCache: updateItemCache,
    filterCustomFields: filterItems,
    changeCustomFieldsFilters: changeItemsFilters,
    clearCustomFieldsFilters: clearItemsFilters,
    sortCustomFields: sortItems,
    paginateCustomFields: paginateItems,
    limitCustomFields: limitItems,
    prefetchCustomFields: prefetchItems
  };
}

export default usePaginatedCustomFields;
