import get from 'lodash/get';
import includes from 'lodash/includes';

import { graphQLReadFinClient } from '../../graphQLClients';

import { DeleteItemGqlQuery } from '../../../../../../../types';

import { generateUuid } from '../../../../../../../utils/generateUuid';
import { getQueryName } from '../../../../../../../utils/getQueryName';
import { productionConsole } from '../../../../../../../utils/productionConsole';
import { errorMessageStatusOrErrorsIsIncorrect } from '../../reactQueryConstants';

interface DeleteItemProps<DeleteItemInput> {
  action: string;
  query: DeleteItemGqlQuery;
  queryInput: DeleteItemInput;
}

const successQueryStatuses = ['deleted', 'success'];

export function deleteFinItem<DeleteItemInput, DeleteItemResponse>({
  action,
  query,
  queryInput
}: DeleteItemProps<DeleteItemInput>) {
  const { rawName, name } = getQueryName(query, 'mutation');

  productionConsole(
    'log',
    'deleteFinItem',
    { action, queryInput },
    rawName,
    JSON.stringify(queryInput)
  );

  const requestId = generateUuid();

  graphQLReadFinClient.addQueryParams(name, requestId);

  return new Promise<DeleteItemResponse>((resolve, reject) => {
    return graphQLReadFinClient
      .request<DeleteItemResponse, DeleteItemInput>(query, queryInput, {
        requestId
      })
      .then((response) => {
        if (includes(successQueryStatuses, get(response, `${action}.status`))) {
          return resolve(response);
        }

        const errors = get(response, `${action}.errors`);

        reject(errors ? errors : errorMessageStatusOrErrorsIsIncorrect);
      })
      .catch((reason) => reject(reason));
  });
}
