import { CompanyNanoID } from '../companies/companiesTypes';
import { CustomFieldLocations } from './customFieldsTypes';

export class CustomFieldCache {
  static companyCustomFields(companyNanoId: CompanyNanoID) {
    return `company-${companyNanoId}-custom-fields`;
  }

  static companyLocationCustomFields(
    companyNanoId: CompanyNanoID,
    location: CustomFieldLocations
  ) {
    return `company-${companyNanoId}-${location}-custom-fields`;
  }
}
