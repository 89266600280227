import {
  CreateItemCacheKeys,
  CreateItemGqlQuery,
  CreatedAt,
  DateFilterType,
  DeleteItemCacheKeys,
  DeleteItemGqlQuery,
  FetchItemsCacheKey,
  FetchItemsGqlQuery,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsSort,
  ID,
  IdFilterType,
  NanoIdFilterType,
  TextFilterType,
  UUID,
  UpdateItemCacheKeys,
  UpdateItemGqlQuery,
  UpdatedAt,
  UuidFilterType
} from '../../types';
import { CompanyID } from '../companies/companiesTypes';
import { UserID } from '../users/usersTypes';

export enum CustomFieldLocations {
  TITLE = 'title',
  HEADER = 'header',
  BILL_FROM = 'bill_from',
  BILL_TO = 'bill_to',
  AMOUNT_DUE = 'amount_due',
  NOTES_AND_TERMS = 'notes_and_terms',
  BEFORE_ITEMS = 'before_items'
}

export type CustomFieldID = ID;
export type CustomFieldUUID = UUID;
export type CustomFieldBody = string;
export type CustomFieldLabel = string;
export type CustomFieldLocation = CustomFieldLocations;
export type CustomFieldCompanyID = CompanyID;
export type CustomFieldUserID = UserID;
export type CustomFieldCreatedAt = CreatedAt;
export type CustomFieldCheckedAt = Date;
export type CustomFieldUpdatedAt = UpdatedAt;
export type CustomFieldOrder = number;
export type CustomFieldOptions = {
  multiline?: boolean;
};

export const enum FetchCustomFieldsSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC'
}

export interface FetchCustomFieldsFilters {
  companyId?: IdFilterType;
  companyNanoId?: NanoIdFilterType;
  createdAt?: DateFilterType;
  id?: IdFilterType;
  location?: TextFilterType;
  updatedAt?: DateFilterType;
  userId?: IdFilterType;
  userNanoId?: NanoIdFilterType;
  uuid?: UuidFilterType;
}

export type FetchCustomFieldsGqlQuery = FetchItemsGqlQuery;

export type FetchCustomFieldsCacheKey = FetchItemsCacheKey;

export type FetchCustomFieldsSort = FetchItemsSort;
export type FetchCustomFieldsPage = FetchItemsPage;
export type FetchCustomFieldsLimit = FetchItemsLimit;

export type CreateCustomFieldGqlQuery = CreateItemGqlQuery;
export type CreateCustomFieldCacheKeys = CreateItemCacheKeys;
export type UpdateCustomFieldGqlQuery = UpdateItemGqlQuery;
export type UpdateCustomFieldCacheKeys = UpdateItemCacheKeys;
export type DeleteCustomFieldGqlQuery = DeleteItemGqlQuery;
export type DeleteCustomFieldCacheKeys = DeleteItemCacheKeys;

export const enum CustomFieldFields {
  LABEL = 'label',
  BODY = 'body',
  LOCATION = 'location',
  ORDER = 'order',
  VALUES = 'values',
  OPTIONS = 'options',
  OPTIONS_MULTILINE = 'options.multiline',
  DEFAULT = 'default',
  SAVE_IN_COMPANY = 'saveInCompany',
  CUSTOM_FIELD = 'customField',
  CUSTOM_FIELD_VALUE = 'customFieldValue'
}
