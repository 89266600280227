import { gql } from 'graphql-request';
import {
  CustomFieldBody,
  CustomFieldCheckedAt,
  CustomFieldCompanyID,
  CustomFieldID,
  CustomFieldLabel,
  CustomFieldLocation,
  CustomFieldOptions,
  CustomFieldOrder,
  CustomFieldUUID
} from '../customFieldsTypes';
import {
  CustomFieldValueDefaultAt,
  CustomFieldValueID,
  CustomFieldValueText
} from '../../customFieldValues/customFieldValuesTypes';

export interface FetchCustomFieldsQueryResponse {
  body: CustomFieldBody;
  checkedAt: CustomFieldCheckedAt;
  companyId: CustomFieldCompanyID;
  customFieldValues: {
    defaultAt: CustomFieldValueDefaultAt;
    id: CustomFieldValueID;
    text: CustomFieldValueText;
  }[];
  id: CustomFieldID;
  label: CustomFieldLabel;
  location: CustomFieldLocation;
  order: CustomFieldOrder;
  options: CustomFieldOptions;
  uuid: CustomFieldUUID;
}

export const FETCH_CUSTOM_FIELDS_QUERY = gql`
  query CustomFields(
    $filters: CustomFieldsFilters
    $limit: Int
    $offset: Int
    $sort: [CustomFieldsSortEnum!]
  ) {
    customFields(
      filters: $filters
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      appVersion
      nodes {
        body
        checkedAt
        companyId
        customFieldValues {
          defaultAt
          id
          text
        }
        id
        label
        location
        options
        order
        uuid
      }
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
        totalCount
        totalPages
      }
      sql
    }
  }
`;
